<template>
    <modal ref="modalHistorial" icon="review" :no-aceptar="true" titulo="Historial" tamano="modal-xl">
        <div class="row mx-0">
            <div class="col">
                <el-table
                :data="comisiones"
                style="width: 100%"
                >
                    <el-table-column prop="comision" label="Comisión" class-name="text-center" />
                    <el-table-column prop="fecha_inicio" label="Fecha Inicio" class-name="text-center" />
                    <el-table-column prop="fecha_fin" label="Fecha Final" class-name="text-center" />
                    <el-table-column prop="nombre" label="Asignada por:" class-name="text-center" />
                    <el-table-column prop="observaciones" width="210" label="Observaciones" class-name="text-center">
                        <template slot-scope="scope">
                            <p class="nombre-historial text-general">
                                {{ scope.row.observaciones }}
                            </p>
                            <el-tooltip placement="bottom" effect="light">
                                <div slot="content" style="max-width:280px;">
                                    {{ scope.row.observaciones }}
                                </div>
                                <p v-if="showObservaciones(scope.row.observaciones)" class="text-general">Ver más</p>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </modal>
</template>

<script>
import Comisiones from "../../../services/comisiones";

export default {
    data(){
        return{
            comisiones: []
        }
    },
    methods: {
        toggle(leechero){
            this.listar_comisiones(leechero)
            this.$refs.modalHistorial.toggle();
        },
        async listar_comisiones(leechero){
            try {
                const {data} = await Comisiones.listar_comisiones(leechero)
                this.comisiones = data
            } catch (e){
                this.error_catch(e)
            }
        },
        showObservaciones(text){
            let n = text.length;
            console.log(n, 'dawdwd');
            if(n >= 150){ return true }
            else if(n < 150  ){ return false }
        }
    },
}
</script>
<style lang="scss" scoped>
.nombre-historial{
    height: 60px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 19px;
}
</style>
